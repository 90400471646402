.zoomButtonBox {
    width: 127vh;

    .zoomButton{
        cursor: pointer;
        position: absolute;
        float: right;
        right: 0%;
        width: 28vh;
        height: 3rem;
        background-color: white;
        font-weight: bold;
        font-size: 2.6vh;
        border: none;
        border-radius: 10px;
        transition: 0.3s all ease-in-out;
    
        &:hover {
            background-color: #a90533;
            color: white;
        }
    }
}

/* Hi-res desktops - big window. */
@media (min-width: 1581px) {
    .zoomButtonBox {
        width: 115vh;

        .zoomButton { 
            font-size: 2.2vh;
            width: 25vh;
            margin-top: 2vh;
        }
    }
}
