@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html, body {margin: 0; height: 100%; overflow: hidden}

@import './Navbar.scss';
@import './Home.scss';
@import './ZoomButton.scss';

body {font-family: 'Work Sans', sans-serif;}

/*
    @media Querys
  * Móvil versión VERTICAL @media(max-width: 500px){}
  * Movil versión HORIZONTAL. @media screen and (min-width:601px) and (max-width: 890px) and (min-height:320px) and (max-height: 440px){}
  * tablet, landscape iPad, laptops VERTICAL @media screen and (min-width:800px) and (max-width: 840px) and (min-height:990px) and (max-height: 1200px) {}
  * tablet, landscape iPad, laptops HORIZONTAL @media screen and (min-width:990px) and (max-width: 1200px) {}
  * Hi-res desktops - big window. @media (min-width: 1581px) {}
*/
