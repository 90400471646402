.principal {
  background-color: #2c2c2c;
  height: 80vh;
  width: 75%;
  text-align: center;
  display: grid;
  grid-template-columns: 80% 20%;
  align-items: center;
  border-radius: 30px;
  margin: 6vh auto;

  .countDown {
    background-image: linear-gradient(rgba(0, 0, 0, 0.623), rgba(0, 0, 0, 0.623)), url("../assets/MIT-BN.jpg");
    background-size:200%;
    background-position: 16% 66%;
    width: 100%;
    height: 100%;
    border-radius: 30px 0 0 30px;

    .webinar {
      display: grid;
      grid-template-columns: 70% 30%;
      width: 50%;
      height: 3rem;
      background-color: $primary;
      border-radius: 10px;
      color: white;
      margin: 4vh;

      span {
        background-color: white;
        border-radius: 0 10px 10px 0;
        color: black;
      }
    }
    .timer {
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      margin-top: 5rem;
      margin-bottom: -5vh;
      color: white;
      font-size: 6vh;
    }
    .joinButton {
      .App {
        position: absolute;
        margin: -19vh 0vh auto 35vh;
        width: inherit;
        
        .zmwebsdk-makeStyles-videoCustomize-8 {
          position: inherit;
          width: 133vh;
          margin: -38vh 0vh 0vh -35vh;
        }
        .zmwebsdk-makeStyles-root-177 {
          top: 13px !important;
          width: 130vh !important;
          height: 70vh !important;
        }
        .zmwebsdk-makeStyles-root-48 { height: 72vh; }
      }
    }
    .liveText {
      background-color: #a90533;
      color: white;
      width: 10%;
      height: 4vh;
      margin: auto;
      border-radius: 10px;
      padding-top: 1vh;
      font-size: 2.2vh;
    }
    .zoomBox {
      float: right;
      margin-right: 32vh;
    }

    .buttonsBox {
        display: flex;
      justify-content: center;
      margin-top: 2vh;

      button {
        background-color: #a90533;
        color: white;
        margin: 1vh;
        width: 28vh;
        height: 6vh;
        border: none;
        border-radius: 10px;
        font-size: 2vh;
      }
    }
  } 
}


// Chat.
body {
  .zmwebsdk-makeStyles-root-87 { height: 85vh; min-width: 209px; }
}

/* Hi-res desktops - big window. */
@media (min-width: 1581px) {
	.principal .countDown {

    .zoomBox { margin-right: 30vh; }
    .joinButton .App {
      margin: -10vh 0vh auto 35vh;

      .zmwebsdk-makeStyles-videoCustomize-8 { width: 123vh; }
      .zmwebsdk-makeStyles-root-177 { width: 121vh !important; }
    }
  } 
  // Chat.
  body {
    .zmwebsdk-makeStyles-root-87 { height: 80.4vh; min-width: 295px; }
  }
}
