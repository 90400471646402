
$primary: #a90533;

.navPadre {
	display: flex;
    // flex-direction: row;
	// justify-content: space-around ;
	text-align: center;
	height: 10vh;
	box-shadow: 0px 0px 13px 4px rgba(150, 150, 150, 0.562);

	img{
		height: 2rem;
		margin: auto 34vh auto 18vh;
	}
	.linkBox {
		margin: auto 0vh;
		display: flex;
		justify-content: center;
		width: 100%;

		.link{
			color: black;
			text-decoration: none;
			margin-right: 3vh;

			&:hover{
				text-decoration: underline $primary 3px;
			}
		}
	}
}
/* Hi-res desktops - big window. */
@media (min-width: 1581px) {
	.navPadre {
		img{ height: 3rem; }
	}
}